// Settings.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const Settings = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">Settings</h1>
        <p className="mt-4 text-lg text-gray-700">
          Customize your dashboard settings and preferences.
        </p>
        {/* Add your settings options here */}
      </div>
    </DashboardLayout>
  );
};

export default Settings;
