// KYC.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const KYC = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">KYC (Know Your Customer)</h1>
        <p className="mt-4 text-lg text-gray-700">
          Complete your KYC process to verify your identity and gain full access to the platform.
        </p>
        {/* Add your KYC form or steps here */}
      </div>
    </DashboardLayout>
  );
};

export default KYC;
