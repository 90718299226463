import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPasswordPage from './components/ForgotPassword';
import Dashboard from './components/Dashboard'; // Import the Dashboard component
import Financials from './components/Financials'; // Import the Financials component
import Referrals from './components/Referrals'; // Import the Referrals page
import Documents from './components/Documents'; // Import the Documents page
import Trading from './components/Trading'; // Import the Trading page
import Education from './components/Education'; // Import the Education page
import Settings from './components/Settings'; // Import the Settings page
import KYC from './components/KYC'; // Import the KYC page
import Profile from './components/Profile'; // Import the Profile page

function App() {
  return (
    <div>
      <Routes>
        {/* Route for the landing page */}
        <Route path="/" element={<LandingPage />} />

        {/* Route for the login page */}
        <Route path="/login" element={<Login />} />

        {/* Route for the registration page */}
        <Route path="/register" element={<Register />} />

        {/* Route for the forgot password page */}
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        {/* Route for the dashboard page */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Route for the financials page */}
        <Route path="/financials" element={<Financials />} />

        {/* Route for the referrals page */}
        <Route path="/referrals" element={<Referrals />} />

        {/* Route for the documents page */}
        <Route path="/documents" element={<Documents />} />

        {/* Route for the trading page */}
        <Route path="/trading" element={<Trading />} />

        {/* Route for the education page */}
        <Route path="/education" element={<Education />} />

        {/* Route for the settings page */}
        <Route path="/settings" element={<Settings />} />

        {/* Route for the KYC page */}
        <Route path="/kyc" element={<KYC />} />

        {/* Route for the profile page */}
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </div>
  );
}

export default App;
