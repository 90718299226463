// Trading.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const Trading = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">Trading</h1>
        <p className="mt-4 text-lg text-gray-700">
          Here you can monitor your trading performance, make trades, and analyze market trends.
        </p>
        {/* Add your trading interface or charts here */}
      </div>
    </DashboardLayout>
  );
};

export default Trading;
