// Documents.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const Documents = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">Documents</h1>
        <p className="mt-4 text-lg text-gray-700">
          Manage your important documents here. Upload, view, or download as needed.
        </p>
        {/* Add your document management features here */}
      </div>
    </DashboardLayout>
  );
};

export default Documents;
