// Education.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const Education = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">Education</h1>
        <p className="mt-4 text-lg text-gray-700">
          Learn more about cryptocurrency, trading, and the latest market trends in our educational resources.
        </p>
        {/* Add your educational content here */}
      </div>
    </DashboardLayout>
  );
};

export default Education;
