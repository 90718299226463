import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import { FaCopy, FaShareAlt } from 'react-icons/fa'; // React Icons
import Footer from './Footer'; // Import Footer component

const Dashboard = () => {
  const [isAITradingOn, setAITradingOn] = useState(true); // AI trading toggle state
  const [showCopyMessage, setShowCopyMessage] = useState(false); // State to show message for copying

  // Function to copy the referral link
  const copyReferralLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText('www.aialgotraders.com/register/username')
        .then(() => {
          setShowCopyMessage(true); // Show a success message
          setTimeout(() => setShowCopyMessage(false), 3000); // Hide the message after 3 seconds
        });
    } else {
      // Fallback for mobile where clipboard might not work
      const input = prompt("Copy the referral link:", "www.aialgotraders.com/register/username");
      if (input) {
        alert("Referral link copied!");
      }
    }
  };

  // Function to share the referral link
  const shareReferralLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Join My AI Trading Team!',
        url: 'www.aialgotraders.com/register/username',
      }).then(() => {
        alert('Link shared successfully!');
      }).catch(err => alert('Error sharing link'));
    } else {
      alert('Sharing is not supported on this device');
    }
  };

  // Toggle AI Trading ON/OFF
  const toggleAITrading = () => {
    setAITradingOn(!isAITradingOn);
  };

  return (
    <DashboardLayout>
      <div className="p-6 space-y-8 bg-black animate__animated animate__fadeIn">
        {/* Welcome Section */}
        <h1 className="text-5xl font-extrabold text-white mb-6 text-center animate__animated animate__fadeIn animate__delay-1s">
          Welcome, [User's Name]! 👋
        </h1>

        {/* Balance Section */}
        <div className="flex flex-col md:flex-row gap-8 items-center justify-between">
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl w-full md:w-1/2 transform hover:scale-105 transition-transform duration-500 ease-in-out animate__animated animate__fadeIn">
            <p className="text-gray-100 font-semibold">Available Balance</p>
            <h2 className="text-3xl font-bold text-white">$[Current Balance]</h2>
          </div>
          <div className="flex gap-6">
            <button className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 ease-in-out hover:scale-105">
              Withdraw
            </button>
            <button className="bg-purple-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-purple-700 transform transition-all duration-300 ease-in-out hover:scale-105">
              Deposit
            </button>
          </div>
        </div>

        {/* Quick Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
            <p className="text-gray-100">Total Earnings</p>
            <h3 className="text-xl font-semibold text-yellow-300">$[Total Earnings]</h3>
          </div>
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
            <p className="text-gray-100">Total Withdrawals</p>
            <h3 className="text-xl font-semibold text-yellow-300">$[Total Withdrawals]</h3>
          </div>
        </div>

        {/* Referral Section */}
        <div className="mt-8 animate__animated animate__fadeIn animate__delay-1s">
          <p className="text-white font-semibold text-xl">Referral Link 🔗</p>
          <div className="flex gap-4 items-center mt-4">
            <input
              type="text"
              readOnly
              value="www.aialgotraders.com/register/username"
              className="w-full p-4 border border-gray-300 rounded-md bg-gray-50 focus:ring-2 focus:ring-purple-600 transition-colors duration-300 ease-in-out"
            />
            <button
              onClick={copyReferralLink}
              className="bg-gray-300 hover:bg-gray-400 px-6 py-3 rounded-md transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              <FaCopy className="text-lg text-gray-800" />
            </button>
            <button
              onClick={shareReferralLink}
              className="bg-purple-600 text-white px-6 py-3 rounded-md hover:bg-purple-700 transform transition-all duration-300 ease-in-out hover:scale-105"
            >
              <FaShareAlt className="text-lg" />
            </button>
          </div>
          {showCopyMessage && <div className="text-green-600 mt-4">Referral link copied successfully!</div>}
        </div>

        {/* AI Investment Portfolio Section */}
        <div className="mt-8 animate__animated animate__fadeIn animate__delay-2s">
          <h2 className="text-2xl font-semibold text-white">Your AI Investment Portfolio 📊</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
            <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
              <p className="text-gray-100">Total Investment</p>
              <h3 className="text-xl font-bold text-white">$[Total Capital]</h3>
            </div>
            <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
              <p className="text-gray-100">Today's AI Trading Profit</p>
              <h3 className="text-xl font-bold text-white">$[Today's Profit]</h3>
            </div>
            <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
              <p className="text-gray-100">Today's Team AI Earnings</p>
              <h3 className="text-xl font-bold text-white">$[Today's Team Earnings]</h3>
            </div>
          </div>
        </div>

        {/* Team Stats Section */}
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Team Stats 🏆</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
            <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
              <p className="text-gray-100">Total Referrals</p>
              <h3 className="text-xl font-bold text-yellow-300">[Total Referrals]</h3>
            </div>
            <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
              <p className="text-gray-100">Total Team Capital</p>
              <h3 className="text-xl font-bold text-yellow-300">$[Total Team Capital]</h3>
            </div>
            <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
              <p className="text-gray-100">Total Team AI Trading Profits</p>
              <h3 className="text-xl font-bold text-yellow-300">$[Total Team AI Profits]</h3>
            </div>
          </div>
        </div>

        {/* Footer Component */}
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
