import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { FaArrowRight, FaRegChartBar, FaShieldAlt, FaClock, FaDollarSign } from 'react-icons/fa'; // Import icons

const LandingPage = () => {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section id="hero" className="bg-gradient-to-r from-pink-500 via-purple-600 to-black py-24 text-center text-white animate-gradient-x">
        <h1 className="text-6xl font-bold mb-6 transition-all hover:text-pink-300">Welcome to AiAlgoTraders</h1>
        <p className="text-2xl mb-10 transition-all hover:text-purple-300 max-w-2xl mx-auto">Empowering your crypto, forex, stocks and futures investments with AI-driven insights 🚀</p>
        <a href="/register" className="bg-green-500 text-white px-10 py-4 rounded-full hover:bg-pink-500 transition-all transform hover:scale-105">
          Get Started <FaArrowRight className="inline-block ml-2" />
        </a>
      </section>

      {/* About Us Section */}
      <section id="about-us" className="py-20 bg-gradient-to-r from-purple-600 via-pink-500 to-black text-white text-center animate-gradient-x">
        <h2 className="text-4xl font-semibold mb-6">The Future of Automated Trading</h2>
        <p className="text-xl mb-6 max-w-4xl mx-auto">
          At AiAlgoTraders, we’re at the cutting edge of AI-driven trading, redefining the investment landscape. By combining the power of machine learning with real-time data analytics, we empower investors to make data-backed decisions that maximize returns across Forex, Crypto, and Stock markets.
        </p>
        <p className="text-xl mb-10 max-w-4xl mx-auto">
          Our vision is clear: harness AI to eliminate the guesswork in trading and deliver consistent, profitable outcomes for our users. Join us today and unlock your potential for wealth growth with a platform that blends the latest in AI technology with the expertise needed to navigate today's dynamic markets. We make investing simple, secure, and impactful.
        </p>
      </section>

      {/* Stats Section */}
      <section id="stats" className="py-20 bg-gradient-to-r from-green-500 to-blue-600 text-white text-center animate-fade-in">
        <h2 className="text-4xl font-semibold mb-6">Our Achievements</h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
          <div className="p-8 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition-all">
            <h3 className="text-4xl font-bold mb-2"><FaDollarSign /> $1.5M+</h3>
            <p className="text-lg">In profits generated for our users through AI-driven trades.</p>
          </div>
          <div className="p-8 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition-all">
            <h3 className="text-4xl font-bold mb-2"><FaRegChartBar /> 1500+</h3>
            <p className="text-lg">Trades executed daily, ensuring high-frequency, profitable actions.</p>
          </div>
          <div className="p-8 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition-all">
            <h3 className="text-4xl font-bold mb-2"><FaShieldAlt /> 100%</h3>
            <p className="text-lg">Security guaranteed with end-to-end encryption and real-time monitoring.</p>
          </div>
        </div>
      </section>

      {/* Mission and Vision Section */}
      <section id="mission-vision" className="py-20 bg-white text-center animate-fade-in">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">Our Vision</h2>
        <p className="text-xl mb-10 max-w-3xl mx-auto text-gray-600">
          To make automated trading effortless, empowering everyone to achieve financial freedom with AI-driven profits.
        </p>

        <h2 className="text-3xl font-semibold mb-6 text-gray-800">Our Mission</h2>
        <p className="text-xl mb-10 max-w-3xl mx-auto text-gray-600">
          To deliver reliable, AI-powered trading solutions that maximize returns and simplify investing for everyone.
        </p>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works" className="py-20 bg-gradient-to-r from-purple-600 via-pink-500 to-black text-white animate-gradient-x">
        <h2 className="text-4xl font-semibold mb-6 text-center">How Does It Work?</h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold mb-4">AI-Driven Trading Technology</h3>
            <p className="text-lg">Sophisticated AI models analyze market trends and execute trades on your behalf. Consistent profits with minimal effort.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold mb-4">Fully Automated</h3>
            <p className="text-lg">Users activate AI trading via their dashboard. Once activated, it generates daily returns, 5 trading days a week.</p>
          </div>
        </div>
      </section>

      {/* Choose Your Style Section */}
      <section id="choose-style" className="py-20 bg-black text-center animate-gradient-x">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">Choose Your Own Style</h2>
        <p className="text-xl mb-10 max-w-3xl mx-auto text-gray-600">
          With AiAlgoTraders, you get to choose your preferred trading style. Whether you want to trade daily with withdrawals or compound your trading for 6 months or 1 year, the choice is yours.
        </p>
        <div className="flex justify-center space-x-8">
          <div className="p-6 bg-gradient-to-r from-green-500 to-pink-500 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold text-white mb-4">Trade Daily with Withdrawals</h3>
            <p className="text-lg text-white">Opt for daily trades with the ability to withdraw your profits at any time.</p>
          </div>
          <div className="p-6 bg-gradient-to-r from-purple-600 to-pink-500 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold text-white mb-4">Compound Your Trading</h3>
            <p className="text-lg text-white">Choose to compound your trading for 6 months or 1 year for maximized returns.</p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gradient-to-r from-green-500 to-pink-500 text-white text-center animate-gradient-x">
        <h2 className="text-4xl font-semibold mb-6">Our Services</h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold mb-4">AI Trading <FaRegChartBar className="inline-block ml-2" /></h3>
            <p className="text-lg">Leverage our advanced AI to trade on your behalf, making profitable trades with minimal input.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold mb-4">Real-Time Insights <FaClock className="inline-block ml-2" /></h3>
            <p className="text-lg">Get real-time market analysis and insights, keeping you updated with the latest trends in the trading world.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-transform transform hover:bg-purple-700">
            <h3 className="text-2xl font-semibold mb-4">Secure Transactions <FaShieldAlt className="inline-block ml-2" /></h3>
            <p className="text-lg">Enjoy secure, seamless transactions for all your trading and investment activities, with top-notch encryption.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
