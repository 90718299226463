import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import { FaCopy, FaShareAlt } from 'react-icons/fa';
import Footer from './Footer';

const Financials = () => {
  const [activeTab, setActiveTab] = useState('withdrawals');

  return (
    <DashboardLayout>
      <div className="financials-page bg-black min-h-screen text-white">
        {/* Navigation Tabs */}
        <div className="tabs flex justify-center bg-black text-white py-4">
          <button
            className={`tab-item mx-4 px-6 py-2 rounded-full text-lg ${
              activeTab === 'withdrawals' ? 'bg-gradient-to-b from-blue-500 to-purple-500 border-b-4 border-pink-500' : 'bg-gradient-to-b from-gray-700 to-gray-900'
            } hover:bg-gradient-to-b hover:from-blue-500 hover:to-purple-500`}
            onClick={() => setActiveTab('withdrawals')}
          >
            Withdrawals
          </button>
          <button
            className={`tab-item mx-4 px-6 py-2 rounded-full text-lg ${
              activeTab === 'packages' ? 'bg-gradient-to-b from-blue-500 to-purple-500 border-b-4 border-pink-500' : 'bg-gradient-to-b from-gray-700 to-gray-900'
            } hover:bg-gradient-to-b hover:from-blue-500 hover:to-purple-500`}
            onClick={() => setActiveTab('packages')}
          >
            Packages
          </button>
          <button
            className={`tab-item mx-4 px-6 py-2 rounded-full text-lg ${
              activeTab === 'earnings' ? 'bg-gradient-to-b from-blue-500 to-purple-500 border-b-4 border-pink-500' : 'bg-gradient-to-b from-gray-700 to-gray-900'
            } hover:bg-gradient-to-b hover:from-blue-500 hover:to-purple-500`}
            onClick={() => setActiveTab('earnings')}
          >
            Earnings
          </button>
        </div>

        {/* Content Sections */}
        <div className="content mt-8 px-4 md:px-12 lg:px-24">
          {activeTab === 'withdrawals' && <Withdrawals />}
          {activeTab === 'packages' && <Packages />}
          {activeTab === 'earnings' && <Earnings />}
        </div>

        {/* Footer Component */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

const TransactionHistory = ({ transactions, title }) => (
  <div className="transaction-history mt-8">
    <h3 className="text-xl font-bold mb-4">{title}</h3>
    <ul className="space-y-4">
      {transactions.map((transaction, idx) => (
        <li
          key={idx}
          className="bg-gradient-to-b from-blue-500 to-purple-500 p-4 rounded flex justify-between items-center"
        >
          <span>Date: {transaction.date}</span>
          <span>Amount: {transaction.amount}</span>
          <span>Status: {transaction.status}</span>
        </li>
      ))}
    </ul>
    <button className="mt-4 bg-gradient-to-b from-blue-500 to-purple-500 hover:bg-gradient-to-b hover:from-blue-600 hover:to-purple-600 text-white py-2 px-4 rounded">
      Load More
    </button>
  </div>
);

const Withdrawals = () => (
  <div className="withdrawals-section">
    <h2 className="text-2xl font-bold mb-4">Withdrawals</h2>
    <div className="balance-card bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg mb-6">
      <p className="text-lg">Current Balance</p>
      <h3 className="text-4xl font-bold text-white-500">$1,250.00</h3>
      <button className="bg-gradient-to-b from-blue-500 to-purple-500 hover:bg-gradient-to-b hover:from-blue-600 hover:to-purple-600 text-white py-2 px-6 rounded mt-4">
        Withdraw
      </button>
    </div>
    <TransactionHistory
      title="Last 10 Transactions"
      transactions={[
        { date: '2024-12-14', amount: '$50.00', status: 'Completed' },
        { date: '2024-12-13', amount: '$25.00', status: 'Completed' },
        { date: '2024-12-12', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-11', amount: '$200.00', status: 'Completed' },
        { date: '2024-12-10', amount: '$150.00', status: 'Completed' },
        { date: '2024-12-09', amount: '$80.00', status: 'Pending' },
        { date: '2024-12-08', amount: '$90.00', status: 'Completed' },
        { date: '2024-12-07', amount: '$40.00', status: 'Pending' },
        { date: '2024-12-06', amount: '$70.00', status: 'Completed' },
        { date: '2024-12-05', amount: '$120.00', status: 'Completed' },
      ]}
    />
  </div>
);

const Packages = () => (
  <div className="packages-section">
    <h2 className="text-2xl font-bold mb-4">Packages</h2>
    <div className="current-package bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg mb-6">
      <p className="text-lg">Current Package</p>
      <h3 className="text-4xl font-bold text-pink-500">Silver Package</h3>
      <p className="text-gray-400">Total AI Purchased: 250 AI</p>
    </div>
    <div className="package-options grid grid-cols-1 md:grid-cols-3 gap-6">
      {['Bronze', 'Silver', 'Gold'].map((packageType, idx) => (
        <div
          key={idx}
          className="package-card bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg text-center"
        >
          <h3 className="text-2xl font-bold text-pink-500">{packageType} Package</h3>
          <p className="text-gray-400 mt-2">Monthly ROI: {10 * (idx + 1)}%</p>
          <button className="mt-4 bg-gradient-to-b from-blue-500 to-purple-500 hover:bg-gradient-to-b hover:from-blue-600 hover:to-purple-600 text-white py-2 px-6 rounded">
            Buy Now
          </button>
        </div>
      ))}
    </div>
    <TransactionHistory
      title="Last 10 Deposits"
      transactions={[
        { date: '2024-12-14', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-13', amount: '$50.00', status: 'Pending' },
        { date: '2024-12-12', amount: '$200.00', status: 'Completed' },
        { date: '2024-12-11', amount: '$150.00', status: 'Completed' },
        { date: '2024-12-10', amount: '$75.00', status: 'Pending' },
        { date: '2024-12-09', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-08', amount: '$250.00', status: 'Completed' },
        { date: '2024-12-07', amount: '$60.00', status: 'Pending' },
        { date: '2024-12-06', amount: '$90.00', status: 'Completed' },
        { date: '2024-12-05', amount: '$120.00', status: 'Completed' },
      ]}
    />
  </div>
);

const Earnings = () => (
  <div className="earnings-section">
    <h2 className="text-2xl font-bold mb-4">Earnings</h2>
    <div className="earnings-summary grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div className="bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg">
        <p className="text-lg">Total Earnings</p>
        <h3 className="text-4xl font-bold text-pink-500">$5,000.00</h3>
      </div>
      <div className="bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg">
        <p className="text-lg">AI Earnings Total (ROI)</p>
        <h3 className="text-4xl font-bold text-pink-500">$3,500.00</h3>
      </div>
      <div className="bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg">
        <p className="text-lg">Referral Commissions</p>
        <h3 className="text-4xl font-bold text-pink-500">$1,000.00</h3>
      </div>
      <div className="bg-gradient-to-b from-blue-500 to-purple-500 p-6 rounded-lg">
        <p className="text-lg">Team AI Earnings</p>
        <h3 className="text-4xl font-bold text-pink-500">$500.00</h3>
      </div>
    </div>
    <TransactionHistory
      title="Last 10 Earnings Transactions"
      transactions={[
        { date: '2024-12-14', amount: '$500.00', status: 'Completed' },
        { date: '2024-12-13', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-12', amount: '$250.00', status: 'Completed' },
        { date: '2024-12-11', amount: '$200.00', status: 'Pending' },
        { date: '2024-12-10', amount: '$150.00', status: 'Completed' },
        { date: '2024-12-09', amount: '$100.00', status: 'Pending' },
        { date: '2024-12-08', amount: '$90.00', status: 'Completed' },
        { date: '2024-12-07', amount: '$60.00', status: 'Completed' },
        { date: '2024-12-06', amount: '$120.00', status: 'Completed' },
        { date: '2024-12-05', amount: '$80.00', status: 'Pending' },
      ]}
    />
  </div>
);

export default Financials;
