// Referrals.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const Referrals = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">Referrals</h1>
        <p className="mt-4 text-lg text-gray-700">
          Track your referral progress and view referral bonuses.
        </p>
        {/* Add your referral details here */}
      </div>
    </DashboardLayout>
  );
};

export default Referrals;
