// Profile.jsx
import React from 'react';
import DashboardLayout from './DashboardLayout';

const Profile = () => {
  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-3xl font-bold text-gray-900">Profile</h1>
        <p className="mt-4 text-lg text-gray-700">
          View and update your personal information and account details.
        </p>
        {/* Add your profile details and edit options here */}
      </div>
    </DashboardLayout>
  );
};

export default Profile;
