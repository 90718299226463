import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';


function RegistrationPage() {
  const [formData, setFormData] = useState({
    sponsorUsername: '',
    firstName: '',
    secondName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    whatsappNumber: '',
    country: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const countries = [
    "USA", "Canada", "UK", "Germany", "Australia", "India", "Nigeria", 
    "South Africa", "Kenya", "Argentina", "Armenia", "Australia", "Austria", 
    "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", 
    "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", 
    "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", 
    "Cambodia", "Cameroon", "Canada", "Cape Verde", "Central African Republic", 
    "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", 
    "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", 
    "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", 
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", 
    "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", 
    "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", 
    "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", 
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", 
    "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", 
    "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", 
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", 
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", 
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", 
    "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", 
    "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", 
    "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", 
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", 
    "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", 
    "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", 
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", 
    "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", 
    "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", 
    "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", 
    "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", 
    "United Arab Emirates", "United Kingdom", "United States", "Uruguay", 
    "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", 
    "Zambia", "Zimbabwe"
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.sponsorUsername) newErrors.sponsorUsername = 'Sponsor Username is required';
    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.secondName) newErrors.secondName = 'Second Name is required';
    if (!formData.username) newErrors.username = 'Username is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid Email is required';
    if (formData.password.length < 6) newErrors.password = 'Password must be at least 6 characters long';
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    if (!formData.whatsappNumber) newErrors.whatsappNumber = 'WhatsApp Number is required';
    if (!formData.country) newErrors.country = 'Country selection is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSubmitting(true);
        // Send data to the API (replace URL with your backend endpoint)
        const response = await axios.post('http://localhost:5000/Register', formData);
        console.log('Form Submitted:', response.data);
        
        // Reset form and errors if submission is successful
        setFormData({
          sponsorUsername: '',
          firstName: '',
          secondName: '',
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          whatsappNumber: '',
          country: ''
        });
        setErrors({});
        alert('Registration successful!');
      } catch (error) {
        console.error('Error during form submission:', error);
        alert('Something went wrong. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
      <motion.section
        className="w-full max-w-md bg-gradient-to-r from-pink-500 to-purple-600 py-8 px-6 rounded-lg shadow-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        
     
        <h2 className="text-3xl font-bold text-center text-white mb-8">Get Started With AiAlgoTraders</h2>




        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Sponsor Username Input */}
          <div className="relative">
            <input
              type="text"
              name="sponsorUsername"
              value={formData.sponsorUsername}
              onChange={handleChange}
              placeholder="Sponsor Username"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.sponsorUsername && <p className="text-red-500 text-sm mt-2">{errors.sponsorUsername}</p>}
          </div>

          {/* First Name Input */}
          <div className="relative">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.firstName && <p className="text-red-500 text-sm mt-2">{errors.firstName}</p>}
          </div>

          {/* Second Name Input */}
          <div className="relative">
            <input
              type="text"
              name="secondName"
              value={formData.secondName}
              onChange={handleChange}
              placeholder="Second Name"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.secondName && <p className="text-red-500 text-sm mt-2">{errors.secondName}</p>}
          </div>

          {/* Username Input */}
          <div className="relative">
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Username"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.username && <p className="text-red-500 text-sm mt-2">{errors.username}</p>}
          </div>

          {/* Email Input */}
          <div className="relative">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.email && <p className="text-red-500 text-sm mt-2">{errors.email}</p>}
          </div>

          {/* Password Input */}
          <div className="relative">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password (min 6 characters)"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.password && <p className="text-red-500 text-sm mt-2">{errors.password}</p>}
          </div>

          {/* Confirm Password Input */}
          <div className="relative">
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.confirmPassword && <p className="text-red-500 text-sm mt-2">{errors.confirmPassword}</p>}
          </div>

          {/* WhatsApp Number Input */}
          <div className="relative">
            <input
              type="text"
              name="whatsappNumber"
              value={formData.whatsappNumber}
              onChange={handleChange}
              placeholder="WhatsApp Number (with country code)"
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            />
            {errors.whatsappNumber && <p className="text-red-500 text-sm mt-2">{errors.whatsappNumber}</p>}
          </div>

          {/* Country Dropdown */}
          <div className="relative">
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full py-3 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
            >
              <option value="">Select Country</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
            {errors.country && <p className="text-red-500 text-sm mt-2">{errors.country}</p>}
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-pink-600 rounded-lg text-white font-semibold hover:bg-pink-700"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Register'}
            </button>
          </div>

          {/* Link to Login */}
          <div className="mt-4 text-center">
            <p className="text-white text-sm">
              Already have an account?{' '}
              <Link to="/login" className="text-pink-400 hover:text-pink-600">Login</Link>
            </p>
          </div>
        </form>
      </motion.section>
    </div>
  );
}

export default RegistrationPage;
